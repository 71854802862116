.imageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
}

.image {
    width: 400px;
    border: 5px solid #190044;
}

.text {
    letter-spacing: 2px;
    font-weight: bold;
}
